import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				EP Agency
			</title>
			<meta name={"description"} content={"Creating Unforgettable Experiences"} />
			<meta property={"og:title"} content={"EP Agency"} />
			<meta property={"og:description"} content={"Creating Unforgettable Experiences"} />
			<meta property={"og:image"} content={"https://vaiolanster.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vaiolanster.com/img/649791345.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vaiolanster.com/img/649791345.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vaiolanster.com/img/649791345.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vaiolanster.com/img/649791345.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vaiolanster.com/img/649791345.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vaiolanster.com/img/649791345.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 200px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--dark"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Contact Us
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--dark" text-align="center">
				We would love to hear from you and discuss how we can make your event a success. For inquiries, please contact us at:
				</Text>
				<Link
					href="mailto:support@vaiolanster.com"
					text-align="center"
					color="--dark"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					support@vaiolanster.com
				</Link>
				<Link
					href="tel:+61 2 8356 5667"
					text-align="center"
					color="--dark"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					+61 2 8356 5667
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--dark" text-align="center">
				Level 1/104 Queen St, Woollahra 
					<br />
					<br />
					NSW 2025, Australia
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});